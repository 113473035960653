import conf from '@/js/conf/conf'
import PullTo from 'vue-pull-to'
import emptyImage from '@/assets/empty.png'
import utils from '@/js/utils'
import service from '@/js/service'
export const mixins = {
  components: {
    PullTo
  },
  props: {
    view: {
      type: Object,
      default() {
        return {}
      }
    },
    state: {
      type: Object,
      default() {
        return {}
      }
    },
    db: {
      type: Object,
      default() {
        return {}
      }
    },
    file: {
      type: Object,
      default() {
        return {}
      }
    },
    mod: {
      type: String,
      default() {
        return 'all'
      }
    },
    params: {
      type: Object,
      default() {
        return {}
      }
    },
    thumbSize: {
      type: Number,
      default: 380
    },
    pageSize: {
      type: Number,
      default: 15
    },
    loginStatus: {
      type: Boolean,
      default: false
    },
    loginUserId: {
      type: Number,
      default: 0
    },
    subtractHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    const schema = {
      scrollTop: 0,
      // 瀑布流各列数据
      columns: [],
      items: [],
      initMessageTypeItem: false,
      currentPage: 0,
      cursorValue: 0,
      cursorValueUp: 0,
      cursorValueDown: 0,
      cursorSkipUp: 0,
      cursorSkipDown: 0,
      skip: 0,
      noUpPage: false,
      noDownPage: false,
      selectMod: false,
      selectIds: {}
    }
    let container_width, container_height
    if (this.state.platform.type === 'desktop') {
      container_width = 632
      container_height = 632
    } else {
      container_width = utils.width()
      container_height = utils.height()
    }
    const data = {
      worksHost: conf.hosts().worksHost,
      orderTypes: conf.orderTypes,
      container_width: container_width,
      container_height: container_height,
      width: container_width - 3,
      height: container_height - 95,
      renew: false,
      fixHeight: 100,
      // 达到保留页数时，重新渲染列表
      remainPages: 100,
      works: {},
      nfts: {},
      userid: 0,
      showUp: false,
      emptyImage: emptyImage,
      schema: schema,
      dt: {
        'selecter': utils.deepClone(schema),
        'all': utils.deepClone(schema)
      },
      loading: {
        'selecter': false,
        'all': false
      },
      cartNums: false
    }
    return data
  },
  computed: {
    isDesktop() {
      return this.state.platform.type === 'desktop'
    },
    isMobile() {
      return this.state.platform.type === 'mobile'
    },
    reqId() {
      return this.state.reqId
    },
    queryCondition() {
      if (this.config.table !== 'draft') return {}
      const condition = {
        table: 'files',
        where: {},
        order: 'id'
      }
      if (this.config.tab === 'recycle') {
        condition.where = { del: '1' }
        condition.order = 'delTime'
      } else {
        condition.where = { del: '0' }
        condition.order = 'id'
      }
      return condition
    },
    thumbWidth() {
      // return this.isDesktop ? this.thumbSize / 2 : this.thumbSize
      return this.thumbSize
    },
    maxColumns() {
      return Math.max(Math.floor(this.width / (this.thumbWidth + 10)), 1)
    },
    columnWidth() {
      return Math.round(this.width / this.maxColumns)
    },
    cardWidth() {
      return this.columnWidth - 10
    },
    // 瀑布流高度
    flowHeight() {
      let height = 0
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const colHeight = its[its.length - 1]._height + its[its.length - 1]._top
        if (colHeight > height) height = colHeight
      }
      return height
    },
    headHeight() {
      return this.config.showHead ? 40 : 0
    },
    listStyle() {
      let fixHeight = 0
      if (['draft', 'paint', 'bankcard', 'address', 'goods'].indexOf(this.table) >= 0) fixHeight = 40
      if (this.cartNums && this.table === 'goods') fixHeight = fixHeight + 40
      if (!this.isDesktop) fixHeight = fixHeight + 40
      if (this.subtractHeight) return { 'height': 'calc(100% - ' + this.subtractHeight + 'px)', 'margin-top': '5px' }
      return { 'height': 'calc(100% - ' + (5 + this.headHeight + fixHeight) + 'px)', 'margin-top': (5 + this.headHeight) + 'px' }
    },
    config() {
      return this.mod === 'selecter' ? this.view.resourceSelecter : this.view.resource
    },
    simple() {
      return !!this.config.simple
    },
    table() {
      return this.config.table
    },
    sift() {
      return this.config.sift
    },
    keyfield() {
      return this.config.keyfield || (this.table + 'id')
    },
    users() {
      return this.state.users
    },
    data() {
      return this.dt[this.mod]
    },
    items() {
      return this.data.items
    },
    itemNums() {
      return this.data.items.length
    },
    columns() {
      return this.data ? this.data.columns : []
    },
    limit() {
      const limit = this.pageSize
      if (!this.isMobile) return limit * 2
      return limit
    }
  },
  watch: {
    // 'view.resourceSelecter.show': {
    //   handler(show) {
    //     this.data.canDelete = false
    //     this.data.selectMod = false
    //     this.data.selectIds = {}
    //     this.update()
    //     if (this.config.type === this.mod && show) {
    //       if (!this.itemNums) this.getData('down')
    //       this.deleteItems()
    //     }
    //   }
    // },
    // 'view.resource.show': {
    //   handler(show) {
    //     this.data.canDelete = false
    //     this.data.selectMod = false
    //     this.data.selectIds = {}
    //     this.update()
    //     if (this.config.type === this.mod && show) {
    //       if (!this.itemNums) this.getData('down')
    //       this.deleteItems()
    //     }
    //   }
    // },
    'view.resource.tab': {
      handler() {
        this.data.canDelete = false
        this.data.selectMod = false
        this.data.selectIds = {}
        this.update()
        if (this.config.type === this.mod && this.config.show) {
          this.renewView()
        }
      }
    }
    // ,
    // 'loginUserId': {
    //   handler() {
    //     this.init()
    //   }
    // }
  },
  mounted() {
    this.calcSize()
    const container = this.scrollContainer()
    if (container) container.onscroll = () => { this.data.scrollTop = container.scrollTop }
    window.addEventListener('resize', this.onResize)
    if (this.config.type === this.mod && this.config.show && !this.itemNums) this.getData('down')
  },
  methods: {
    addResource() {
      if (this.table === 'work' || this.table === 'draft') {
        this.view.createNewfile = false
        setTimeout(() => {
          this.view.createNewfile = true
        }, 10)
      } else {
        this.emit('openResourceMng', [{ 'table': this.table, 'act': 'add' }])
      }
    },
    getPaintTip(item) {
      if (item.ai_status === 2 && item.images[0]) return ''
      if (utils.time('time') - item.add_at > 60) {
        return 'AI作画排队中，请耐心等待...'
      } else {
        return 'AI作画中，请稍等...'
      }
    },
    longTap(id) {
      if (this.data.selectMod) {
        this.emit('cancelSelect')
      } else {
        this.data.selectMod = true
        this.data.selectIds[id] = true
        this.emit('setSelectData', [this.data.selectMod, this.data.selectIds])
        this.update()
      }
    },
    select(id) {
      this.data.selectMod = true
      if (this.data.selectIds[id]) {
        delete this.data.selectIds[id]
      } else {
        if (this.config.maxSelectCount > 0 && Object.keys(this.data.selectIds).length >= this.config.maxSelectCount) {
          this.message('最多选择' + this.config.maxSelectCount + '项')
          return
        }
        this.data.selectIds[id] = true
      }
      this.emit('setSelectData', [this.data.selectMod, this.data.selectIds])
      this.update()
    },
    // 设置购物车数据
    changeCartNum(item, tab = '', num = 0) {
      if (tab && num) item.cart[tab] = num
      let cartNums = 0
      for (var i in this.data.items) {
        if (this.data.items[i].cart && (this.data.items[i].cart['10001'] > 0 || this.data.items[i].cart['10002'] > 0)) {
          cartNums = cartNums + this.data.items[i].cart['10001'] + this.data.items[i].cart['10002']
        }
      }
      this.cartNums = cartNums
      this.update()
    },
    // 我要购买
    buyNow() {
      const goods = []
      for (var i in this.data.items) {
        if (this.data.items[i].cart && (this.data.items[i].cart['10001'] > 0 || this.data.items[i].cart['10002'] > 0)) {
          goods.push(this.data.items[i])
        }
      }
      this.emit('buyNow', goods)
      this.update()
    },
    selectAndUse(id = '') {
      if (id) {
        this.data.selectIds = {}
        this.data.selectIds[id] = true
      }
      this.data.selectMod = true
      this.emit('setSelectData', [this.data.selectMod, this.data.selectIds, 'use'])
      this.update()
    },
    cancelSelect() {
      this.data.selectMod = false
      this.data.selectIds = {}
      this.update()
    },
    // 转场
    goto(mod, dt, type) {
      if (type) {
        this.config.type = type
        return
      }
      this.emit('goto', [mod, dt])
    },
    getSelectItems() {
      const items = []
      for (var i in this.data.items) {
        if (this.data.selectIds[this.data.items[i][this.keyfield]]) {
          if (this.table === 'nft') {
            const work = this.works[this.data.items[i].workid] || {}
            const nft = this.nfts[this.data.items[i].publishid] || {}
            if (nft.sn) this.data.items[i].name = this.data.items[i].name + ' #' + utils.padding(nft.sn, 4)
            this.data.items[i].width = work.width
            this.data.items[i].height = work.height
          }
          items.push(this.data.items[i])
        }
      }
      return items
    },
    // 逻辑删除
    deleteItems() {
      let selectIds = {}
      if (this.mod === 'all') selectIds = this.state.deleteAllresource
      const ids = []
      for (var id in selectIds) {
        ids.push(id.toString())
      }
      if (ids.length) {
        const items = []
        for (var i in this.data.items) {
          if (ids.indexOf(this.data.items[i][this.keyfield].toString()) < 0) items.push(this.data.items[i])
        }
        this.data.items = items
        if (this.mod === 'all') this.state.deleteAllresource = {}
        this.renderItems(this.data.items, true)
      }
      this.data.canDelete = false
      this.data.selectMod = false
      this.data.selectIds = {}
      this.update()
    },
    // 移除
    removeItem(id) {
      if (id) id = id.toString()
      for (var i in this.data.items) {
        if (this.data.items[i][this.keyfield] && this.data.items[i][this.keyfield].toString() === id) {
          this.data.items.splice(i, 1)
        }
      }
      this.renderItems(this.data.items, true)
    },
    // 设置属性
    setItem(id, key, value) {
      if (id) id = id.toString()
      for (var i in this.data.items) {
        if (this.data.items[i][this.keyfield] && this.data.items[i][this.keyfield].toString() === id) {
          this.data.items[i][key] = value
          return this.data.items[i]
        }
      }
    },
    scrollContainer() {
      const main = document.getElementById('resource-' + this.mod + '-container')
      if (main) {
        const scroll = main.getElementsByClassName('scroll-container')
        if (scroll.length) return scroll[0]
      }
    },
    onResize() {
      if (!this.config.show) return
      this.calcSize()
      // this.backTop()
      // this.data.scrollTop = 0
      this.renderItems(this.data.items, true)
    },
    backTop() {
      const container = this.scrollContainer()
      if (container) {
        setTimeout(() => {
          container.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        }, 100)
      }
    },
    renewView() {
      this.backTop()
      this.loadData(() => {}, 'renew')
    },
    showItem(item) {
      if (item._top <= this.data.scrollTop - this.height && item._top + item._height >= this.data.scrollTop - this.height) return true
      if (item._top >= this.data.scrollTop - this.height && item._top <= this.data.scrollTop + this.height + this.height) return true
      return false
    },
    hintBankAccount: utils.hintBankAccount,
    padding: utils.padding,
    formateTime: utils.formateTime,
    formateNums: utils.formateNums,
    formateMoney: utils.formateMoney,
    formateBytes: utils.formateBytes,
    // 调用父组件方法
    emit(fn, params) {
      if (!fn) return
      this.$emit('receive', fn, params || [])
    },
    // 提示信息
    message(msg, type) {
      this.emit('message', [msg, type])
    },
    alert(msg, fn) {
      this.emit('alert', [msg, fn])
    },
    // 确认操作
    confirm(msg, fn, title) {
      this.emit('confirm', [msg, fn, title])
    },
    getMinHeight(arr) {
      const a = []
      for (let i = 0; i < arr.length; i++) {
        a.push(parseInt(arr[i]._height) + parseInt(arr[i]._top))
      }
      return Math.min.apply(null, a)
    },
    getMinIndex(val) {
      const arrIndex = []
      for (let i = 0; i < this.columns.length; i++) {
        const its = this.columns[i].items
        const height = its[its.length - 1]._height
        const top = its[its.length - 1]._top
        if (parseInt(height) + parseInt(top) === val) {
          arrIndex.push(i)
        }
      }
      return arrIndex
    },
    renderItems(items, refresh = false) {
      if (refresh) this.data.columns = []
      if (!items.length) return
      // 初始化列、列的第一个元素
      let idx = 0
      if (this.columns.length < this.maxColumns || refresh) {
        for (let i = 0; i < this.maxColumns; i++) {
          if (!this.columns[i] && items[idx]) {
            items[idx]._top = 0
            items[idx]._raw_width = this.thumbWidth
            items[idx]._raw_height = 0
            items[idx]._height = items[idx]._raw_height + this.fixHeight
            this.columns.push({ items: [items[idx]] })
            idx++
          }
        }
      }
      // 对剩余元素的判断，应该放到哪一列
      for (var index = idx; index < items.length; index++) {
        // 找到高度最小的一列，可能有多个
        const tmp = []
        for (let i = 0; i < this.columns.length; i++) {
          const its = this.columns[i].items
          tmp.push({
            _height: its[its.length - 1]._height,
            _top: its[its.length - 1]._top
          })
        }
        // 获取最矮的列高度
        const minHeight = this.getMinHeight(tmp)
        // 获取最矮列的索引
        let minHeightIdx = 0
        const arrIndex = this.getMinIndex(minHeight)
        // 出现高度一样的，取索引最小的
        if (arrIndex.length > 0) {
          minHeightIdx = Math.min.apply(null, arrIndex)
        }
        // 设置属性
        items[index]._top = minHeight
        items[index]._raw_width = this.thumbWidth
        items[index]._raw_height = 0
        items[index]._height = items[index]._raw_height + this.fixHeight
        this.columns[minHeightIdx].items.push(items[index])
      }
    },
    calcWorkSize(w, h, maxSize = 80) {
      if (w < maxSize && h < maxSize) return { width: w, height: h }
      let width, height
      if (w > h) {
        width = maxSize
        height = Math.round(width * h / w)
      } else {
        height = maxSize
        width = Math.round(height * w / h)
      }
      return { width: width, height: height }
    },
    imgStyle(work, maxSize = 80) {
      if (!work) return {}
      const size = this.calcWorkSize(work.width, work.height, maxSize)
      const top = (maxSize - size.height) / 2
      return { 'width': size.width + 'px', 'height': size.height + 'px', 'margin-top': top + 'px' }
    },
    paintStyle(work, maxSize = 80) {
      if (!work) return {}
      const size = this.calcWorkSize(work.width, work.height, maxSize)
      return { 'width': size.width + 'px', 'height': size.height + 'px' }
    },
    calcSize() {
      if (this.state.platform.type === 'desktop') {
        this.container_width = 632
        this.container_height = 632
      } else {
        this.container_width = utils.width()
        this.container_height = utils.height()
      }
      this.width = this.container_width - 3
      this.height = this.container_height - 95 - this.headHeight
    },
    showActionSheet(id, item) {
      if (this.table === 'draft') {
        this.showDraftActionSheet(id, item)
        return
      }
      const cb = (id, data, dt, type) => {
        if (typeof data.public !== 'undefined') item.public = data.public
      }
      const actions = {
        show: true,
        title: '请选择',
        btns: []
      }
      if (this.table === 'work' || this.table === 'paint') {
        actions.btns = actions.btns.concat([
          {
            title: item.public ? '设为私密' : '设为公开',
            cb: () => {
              this.emit('actionOperate', [this.table, item, id, { public: item.public ? 0 : 1 }, cb])
            }
          }
          // { title: '登记版权', cb: () => { } },
          // { title: '发行藏品', cb: () => { } }
        ])
      }
      actions.btns.push({ title: '查看', cb: () => { this.viewResource(id, item) } })
      if (this.table === 'paint') {
        actions.btns.push({ title: '下载', cb: () => { this.downloadPaint(id, item) } })
        actions.btns.push({ title: '二创', cb: () => { this.importWebimage(id, item) } })
      } else {
        actions.btns.push({ title: this.table === 'work' ? '打开' : '编辑', cb: () => { this.editResource(id) } })
      }
      actions.btns.push({ title: '删除', cb: () => { this.confirmDelete(id) } })
      this.view.actionSheet = actions
    },
    importWebimage(id, item) {
      service.getCosUrl(item.images[0], 'paint', item.add_at) || ''
      const tags = []
      if (item.tags) {
        for (const i in item.tags) {
          tags.push(item.tags[i])
        }
      }
      tags.push('AI作画')
      this.emit('importWebimage', [this.getPaint(item), item.name, () => {}, tags])
    },
    downloadPaint(id, item) {
      if (!item.images.length) return
      if (utils.isAlipayClient() || utils.isWeixinClient()) {
        this.alert('请长按保存图片')
      } else {
        window.open(this.getPaint(item))
      }
    },
    // 是否展示
    ifShow(item) {
      if (this.table === 'goods') {
        if (this.config.sift === '0') {
          return true
        } else if (this.config.sift === '1') {
          return item.cols === '48' && item.rows === '48'
        } else if (this.config.sift === '2') {
          return item.cols === '48' && item.rows === '64'
        } else if (this.config.sift === '3') {
          return item.cols === '64' && item.rows === '64'
        }
      }
    },
    showDraftActionSheet(id, item) {
      const actions = {
        show: true,
        title: '请选择',
        btns: []
      }
      const cb = (type) => {
        if (type === 'restoreFile') {
          this.emit('restoreFile', [item.fileId])
          setTimeout(this.renewView, 100)
        } else if (type === 'deleteFile') {
          this.emit('deleteFile', [item.fileId])
          this.removeItem(item.fileId)
        } else if (type === 'delFile') {
          this.emit('delFile', [item.fileId])
          this.removeItem(item.fileId)
        } else if (type === 'openFile') {
          this.emit('openFile', [item.id])
        } else if (type === 'publishWork') {
          this.emit('publishWork', [item])
        } else if (type === 'openWork') {
          this.openWorkViewer(item.userid, item.workid)
        }
      }
      if (this.config.tab === 'recycle') {
        actions.btns = actions.btns.concat([
          { title: '恢复', cb: () => { cb('restoreFile') } },
          { title: '彻底删除', cb: () => { cb('deleteFile') } }
        ])
      } else {
        if (item.publish) {
          actions.btns = [{ title: '查看', cb: () => { cb('openWork') } }]
        }
        actions.btns = actions.btns.concat([
          { title: '打开', cb: () => { cb('openFile') } },
          { title: '发布', cb: () => { cb('publishWork') } },
          { title: '删除', cb: () => { cb('delFile') } }
        ])
      }
      this.view.actionSheet = actions
    },
    openWorkViewer(userid, workid) {
      const data = {
        userid: userid,
        workid: workid,
        publishid: 0
      }
      this.emit('openWorkViewer', [{ data: data }])
    },
    viewResource(id, item) {
      if (this.table === 'draft') {
        if (this.config.tab === 'recycle') return
        this.emit('openFile', [item.id])
      } else if (this.table === 'paint') {
        if (item.ai_status === 2 && item.images.length) {
          const images = []
          const names = []
          for (const i in item.images) {
            images.push(service.getCosUrl(item.images[i], 'paint', item.add_at))
            names.push(item.name)
          }
          this.emit('openGridyViewer', [{ data: { images: images, names: names, idx: 0, type: 'imagesViewer' }}])
        }
      } else if (this.table === 'work') {
        this.openWorkViewer(this.loginUserId, id)
      } else {
        this.emit('openResourceMng', [{ 'table': this.table, 'act': 'view', 'id': id }])
      }
    },
    editResource(id) {
      if (this.table === 'work') {
        this.emit('editRemoteWork', [id, true, this.loginUserId])
      } else {
        this.emit('openResourceMng', [{ 'table': this.table, 'act': 'edit', 'id': id }])
      }
    },
    confirmDelete(id) {
      this.confirm('确定删除吗？', (action) => {
        if (action === 'confirm') this.delete(id)
      })
    },
    delete(id) {
      const ids = [id]
      const selectIds = {}
      selectIds[id] = true
      this.state.deleteAllresource = selectIds
      this.deleteItems()
      if (this.table === 'work') {
        for (const i in ids) {
          service.put(this.table, ids[i], { flag: 0 }, (dt, type) => {
            this.view.resourceSelecter.renew = utils.time('time')
          }, true)
        }
      } else {
        service.batchDelete(this.table, ids, (dt, type) => {
          this.view.resourceSelecter.renew = utils.time('time')
        }, true)
      }
    },
    init(backTop) {
      if (backTop) this.backTop()
      this.dt[this.mod] = utils.deepClone(this.schema)
      this.emit('setSelectData', [false, []])
    },
    update() {
      this.$forceUpdate()
    },
    updateData(updateHash) {
      if (updateHash && !this.config.pop) this.emit('setLocationHash')
      if (!this.itemNums) {
        this.getData('down')
      } else {
        this.update()
      }
    },
    getPaint(item) {
      if (!item || !item.images || !item.images[0]) return ''
      return service.getCosUrl(item.images[0], 'paint', item.add_at) || ''
    },
    getAvatar(item) {
      if (!item || !item.avatar) return ''
      const ver = item.avatar_at ? Math.round(new Date(item.avatar_at) / 1000) : ''
      return service.getCosUrl(item.avatar, 'avatar', ver) || ''
    },
    getAttachment(item) {
      if (!item || !item.file_path) return ''
      const ver = item.updated_at ? Math.round(new Date(item.updated_at) / 1000) : ''
      let type = 'attachments'
      if (item.file_path.split('/paint/').length > 1) type = 'paint'
      return service.getCosUrl(item.file_path.replace('public/' + type + '/', 'cos://') + item.attachment, type, ver) || ''
    },
    getContent(item, split) {
      if (!item || !item.summary) return ''
      return utils.fomateContent(item.summary, split)
    },
    loadData(loaded, direction) {
      if (this.config.show) {
        this.renew = direction === 'renew'
        if (direction === 'renew') {
          direction = 'down'
          this.data.currentPage = 0
          this.data.cursorValue = 0
          this.data.cursorValueUp = 0
          this.data.cursorValueDown = 0
          this.data.cursorSkipUp = 0
          this.data.cursorSkipDown = 0
          this.data.skip = 0
          this.data.noUpPage = false
          this.data.noDownPage = false
        }
        this.getData(direction, () => {
          this.renew = false
          loaded('done')
        })
      }
    },
    // 分页读取本地数据
    async getLocalData(direction, cb) {
      direction = direction || 'down'
      if (!this.config.show) return cb && cb()
      if (direction === 'up' && this.data.noUpPage) return cb && cb()
      if (direction === 'down' && this.data.noDownPage) return cb && cb()
      if (this.loading[this.mod]) return cb && cb()
      if (direction === 'up') {
        this.data.currentPage--
      } else if (direction === 'down') {
        this.data.currentPage++
      }
      if (this.data.currentPage <= 0) {
        this.data.noUpPage = true
        return cb && cb()
      }
      this.loading[this.mod] = true
      const items = await this.db.pageGet(this.queryCondition.table, this.queryCondition.where, this.queryCondition.order, this.data.currentPage, this.limit)
      if (this.renew) this.init(true)
      // console.error('renew', this.renew)
      this.data.items = this.data.items.concat(items)
      const total = await this.db.totalCount(this.queryCondition.table, this.queryCondition.where)
      // console.error('getLocalData', direction, this.data.currentPage, this.data, this.itemNums, total, this.queryCondition, this.data.currentPage, this.limit, this.isMobile, this.state)
      if (this.itemNums >= total) this.data.noDownPage = true
      this.renderItems(items, this.renew)
      this.loading[this.mod] = false
      cb && cb()
    },
    // 分页读取数据
    async getData(direction, cb) {
      if (this.table === 'draft') {
        this.getLocalData(direction, cb)
        return
      }
      if (!this.table || !this.keyfield) return
      direction = direction || 'down'
      if (!this.config.show) return cb && cb()
      if (direction === 'up' && this.data.noUpPage) return cb && cb()
      if (direction === 'down' && this.data.noDownPage) return cb && cb()
      if (this.loading[this.mod]) return cb && cb()
      this.loading[this.mod] = true
      let resouceTable = this.table
      const params = {
        'sort_field': this.keyfield ? this.keyfield : (this.table + 'id'),
        'sort': 'desc',
        'limit': this.limit,
        'skip': direction === 'up' ? this.data.cursorSkipUp : this.data.cursorSkipDown,
        'cursor_value': direction === 'up' ? this.data.cursorValueUp : this.data.cursorValueDown,
        'direction': direction
      }
      if (this.table === 'bankcard' || this.table === 'address') {
        params.mod = this.mod
        params.sort_field = 'isdefault'
      } else if (this.table === 'topics') {
        params.sort_field = 'recommended_at'
        params.recommended = 1
      } else if (this.table === 'user_follow') {
        resouceTable = 'user'
        params.sort_field = 'login_at'
        params.userid = this.loginUserId
        params.mod = 'follow'
      } else if (this.table === 'work') {
        params.sort_field = 'update_at'
        params.userid = this.loginUserId
      } else if (this.table === 'paint') {
        params.sort_field = 'paintid'
        params.userid = this.loginUserId
      } else if (this.table === 'nft') {
        params.sort_field = 'nftid'
        params.userid = this.loginUserId
        params.public = 1
      } else if (this.table === 'goods') {
        params.sort_field = 'sort'
        params.sort = 'asc'
        params.category_id = 10012
      } else if (this.table.slice(0, -2) === 'attachments') {
        resouceTable = 'attachments'
        params.sort_field = 'id'
        params.userid = this.loginUserId
        params.type = this.table.slice(-1)
      } else {
        params.mod = this.mod
      }
      // console.error('params', this.mod, params, direction, this.data.currentPage, this.data)
      service.listGet(resouceTable, params, (res, type) => {
        if (type === 'success') {
          let refresh = false
          if (direction === 'up') {
            this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorSkipUp = res.data.cursor_skip_up
            if (!this.data.cursorValueDown && !utils.isEmpty(res.data.cursor_value_down)) this.data.cursorValueDown = res.data.cursor_value_down
            this.data.noUpPage = utils.isEmpty(this.data.cursorValueUp)
            this.data.items = res.data.items.concat(this.data.items)
          } else if (direction === 'down') {
            if ((res.data.count === this.limit && this.data.currentPage >= this.remainPages) || this.renew) {
              if (res.data.count === this.limit && this.data.currentPage >= this.remainPages) {
                this.init(true)
                this.showUp = true
              } else {
                this.init()
              }
              refresh = true
            }
            this.data.currentPage++
            if (!this.data.cursorValueUp && !utils.isEmpty(res.data.cursor_value_up)) this.data.cursorValueUp = res.data.cursor_value_up
            this.data.cursorValueDown = res.data.cursor_value_down
            this.data.cursorSkipDown = res.data.cursor_skip_down
            this.data.noDownPage = utils.isEmpty(this.data.cursorValueDown)
            this.data.items = this.data.items.concat(res.data.items)
          }
          this.data.skip = utils.getInt(res.data.skip)
          if (res.data.users) {
            for (var userid in res.data.users) {
              this.users[userid] = res.data.users[userid]
            }
          }
          if (res.data.nfts) {
            for (var nftid in res.data.nfts) {
              this.nfts[nftid] = res.data.nfts[nftid]
            }
          }
          if (res.data.works) {
            for (var workid in res.data.works) {
              this.works[workid] = res.data.works[workid]
            }
          }
          if (this.table === 'goods') {
            for (var i in this.data.items) {
              const match = utils.calFrameSize(this.data.items[i].goods_name)
              this.data.items[i].cart = { '10001': 0, '10002': 0 }
              this.data.items[i].cols = match.cols
              this.data.items[i].rows = match.rows
              this.data.items[i].name = this.data.items[i].goods_name.replace(match.str, '')
              this.data.items[i].info = match.cols + 'x' + match.rows
            }
          }
          if (direction === 'up') {
            this.renderItems(this.data.items, true)
          } else {
            this.renderItems(res.data.items, refresh)
          }
        } else {
          this.message(res, type)
        }
        cb && cb()
        this.loading[this.mod] = false
      }, false, false)
    }
  }
}
