/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2022-1-29
 * @copyright Gridy.Art
 */
import paletteConf from '@/js/conf/palettes'
import skusConf from '@/js/conf/skus'
import viewConf from '@/js/conf/viewConf'
import stateConf from '@/js/conf/stateConf'
import schemaConf from '@/js/conf/schemaConf'
const paletteOpts = []
const paletteData = {
  brickfyGray: Object.values(paletteConf.brickfyGray),
  brickfyArr: paletteConf.brickfyArr
}
for (const k in paletteConf.names) {
  paletteOpts.push({ value: k, label: paletteConf.names[k] })
  paletteData[k] = (Array.isArray(paletteConf[k])) ? paletteConf[k] : Object.values(paletteConf[k])
}
const brickDt = {}
for (const id in paletteConf.brickfy) {
  brickDt[paletteConf.brickfy[id].toUpperCase()] = id
}
const darkenedBrickDt = {}
for (const id in paletteConf.darkenedBrickfy) {
  darkenedBrickDt[paletteConf.darkenedBrickfy[id].toUpperCase()] = id
}
const hosts = function(e) {
  // eslint-disable-next-line
  if(e) env = e;
  var conf = {
    mainHost: 'https://gridy.art/',
    cdnHost: 'https://cdn.gridy.art/',
    apiHost: 'https://api.gridy.art/',
    zoneApiHost: 'https://q.gridy.art/api/v3/',
    cosCdnHost: 'https://coscdn.gridy.art/',
    worksHost: 'https://cdn.gridyart.com/',
    downloadUrl: 'https://gridyart.com/',
    storeHost: 'https://store.gridy.art/'
    // storeHost: 'http://store.gridyart.cn/'
  }
  // eslint-disable-next-line
  if (env === 'dev') {
    conf = {
      mainHost: 'https://gridy.art/',
      cdnHost: 'https://cdn.gridy.art/',
      apiHost: 'https://api.gridy.art/',
      zoneApiHost: 'https://q.gridy.art/api/v3/',
      cosCdnHost: 'https://coscdn.gridy.art/',
      worksHost: 'https://cdn.gridyart.com/',
      downloadUrl: 'https://gridyart.com/',
      storeHost: 'https://store.gridy.art/'
      // storeHost: 'http://store.gridyart.cn/'
    }
  // eslint-disable-next-line
  } else if (env === 'test') {
    conf = {
      mainHost: 'https://gridyart.com/',
      cdnHost: 'https://cdn.gridyart.com/',
      apiHost: 'https://api.gridyart.com/',
      zoneApiHost: 'https://q.gridyart.com/api/v3/',
      cosCdnHost: 'https://coscdn.gridyart.com/',
      worksHost: 'https://cdn.gridyart.com/',
      downloadUrl: 'https://gridyart.com/',
      storeHost: 'https://store.gridy.art/'
      // storeHost: 'http://store.gridyart.cn/'
    }
  }
  return conf
}
// 腾讯COS桶
const cosBucket = {
  cdnHost: hosts().cosCdnHost || '',
  bucket: 'gridyart-1313190797',
  region: 'ap-guangzhou'
}
export default {
  ver: '1.0.2',
  pubAt: '1645692748',
  hosts: hosts,
  cosBucket: cosBucket,
  tmp: {
    latestOpen: [],
    webimage: '',
    uploadAssets: [],
    dt: {},
    cache: '',
    objs: [],
    partObj: '',
    selectedColor: '',
    selectObjs: [],
    lastPos: [],
    clipboard: '',
    file: {},
    catalogid: 0,
    tags: [],
    show: true,
    share: false,
    lock: {},
    count: 0
  },
  algorithms: {
    'differenceRGB': 'RGB',
    'differenceSquared': 'Squared',
    'differenceEuclideanRGB': 'EuclideanRGB',
    'differenceEuclideanLab': 'EuclideanLab',
    'differenceCie94': 'CIE94',
    'differenceCiede2000': 'CIEDE2000',
    'differenceDin99o': 'DIN99o'
  },
  // 作品分类
  catalogs: [
    { id: 0, label: '请选择...' },
    { id: 1, label: '节日节气' },
    { id: 2, label: '教育文化' },
    { id: 4, label: '体育健身' },
    { id: 5, label: '医疗健康' },
    { id: 3, label: '服装饰品' },
    { id: 6, label: '家居日用' },
    { id: 17, label: '工具用品' },
    { id: 13, label: '玩具游戏' },
    { id: 7, label: '餐饮美食' },
    { id: 11, label: '蔬菜水果' },
    { id: 10, label: '动物植物' },
    { id: 8, label: '商务办公' },
    { id: 15, label: '汽车交通' },
    { id: 14, label: '建筑空间' },
    { id: 12, label: '科学宇宙' },
    { id: 9, label: '人物动漫' },
    { id: 16, label: '图标形状' },
    { id: 18, label: '其他' }
  ],
  // 圈子分类
  categories: [
    { id: 0, label: '请选择...' },
    { id: 1, label: '广场' },
    { id: 2, label: '百格圈' },
    { id: 3, label: '拼图圈' },
    { id: 4, label: '数藏圈' },
    { id: 5, label: '游戏圈' },
    { id: 6, label: '头条圈' },
    { id: 7, label: '反馈圈' }
  ],
  // 1x1拼图块
  brickImg: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAAA3NCSVQICAjb4U/gAAAAG1BMVEX///+jo6OMjIyEhIR7e3tzc3NmZmZXV1c/Pz/lBLefAAAACXRSTlMAIiIiIiIiIiIiUPyZAAAACXBIWXMAAAsSAAALEgHS3X78AAAAGHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3NPsx9OAAAAFnRFWHRDcmVhdGlvbiBUaW1lADA1LzE3LzIzR23q9gAAANVJREFUGJVd0U0WgiAQB3CvgJ0gyPYB2l7R9mW2r0wvkHqBdI4dM6D2mg3vB3++3gSlMfl1qSqQQnHOla9dkNKojXGjN2fhr5mtcPWWCZ8gS0ZZO+m8FeTDxjv0l7EMzZXwPmi0SGfLGL1bHWXWcTo/VkWXP5c6yJLVydO6OP/5vvhEfiy+4f74vbgn9/P9sXMzH3Ac0br5+Oe1nUFXcCHvoUarYpjwC7KdnPMGxlLlLXQlWVcDQA8wvQ0ZA1h22ZkCYHejJfYqb/rumlG/bAj7+KpdQ78CGm5nOUVi7gAAAABJRU5ErkJggg==',
  // 作品类型
  workTypes: [
    { id: 0, label: '像素画', shapeid: 'default', icon: 'my-square-full' },
    { id: 1, label: '百格画', shapeid: 'square', icon: 'my-square-shape' },
    { id: 2, label: '拼图', shapeid: 'circle', icon: 'my-circle-shape' }
  ],
  // 网格形状
  fillShapes: [
    { id: 0, label: '默认', shapeid: 'default', icon: 'my-square-full' },
    { id: 1, label: '方形', shapeid: 'square', icon: 'my-square-shape' },
    { id: 2, label: '圆形', shapeid: 'circle', icon: 'my-circle-shape' }
  ],
  // 默认拼图背景用色
  // [白色, 黄色, 蓝色，红色，草绿，深紫]
  defaultBgColor: ['#FEFBF5', '#FFCC00', '#005FAD', '#D92817', '#00A73C', '#584C9D'],
  // ??
  rankOpts: [
    { id: '1', label: '最新上架' },
    { id: '2', label: '最新创作' },
    { id: '3', label: '最受欢迎' },
    { id: '4', label: '价格从高到低' },
    { id: '5', label: '价格从低到高' }
  ],
  // ??
  tabNames: {
    'draft': '我的草稿',
    'works': '我的作品',
    'mynft': '我的藏品',
    'sellnft': '售卖藏品',
    'find': '发现',
    'creator': '创作者',
    'myassets': '我的素材',
    'assets': '公共素材库',
    'setting': '设置',
    'msg': '消息',
    'fans': '粉丝',
    'worth': '资产',
    'orders': '订单',
    'faq': '快速入门',
    'hotkeys': '快捷键列表',
    'update': '检查更新',
    'feedback': '发送反馈',
    'aboutus': '关于我们',
    'login': '登录'
  },
  canvasRatio: [
    { id: '1-1', label: '1:1', tip: '64x64', icon: 'my-ratio-1-1', cols: 64, rows: 64 },
    { id: '3-4', label: '3:4', tip: '48x64', icon: 'my-ratio-3-4', cols: 48, rows: 64 },
    { id: '4-3', label: '4:3', tip: '64x48', icon: 'my-ratio-4-3', cols: 64, rows: 48 },
    { id: '4-5', label: '4:5', tip: '64x80', icon: 'my-ratio-9-16', cols: 64, rows: 80 },
    { id: '5-4', label: '5:4', tip: '80x64', icon: 'my-ratio-16-9', cols: 80, rows: 64 }
    // { id: '2-3', label: '2:3', tip: '64x96', icon: 'my-ratio-9-16', cols: 64, rows: 96 },
    // { id: '3-2', label: '3:2', tip: '96x64', icon: 'my-ratio-16-9', cols: 96, rows: 64 }
  ],
  sizeOpts: {
    '1-1': [
      { cols: 32, rows: 32 },
      { cols: 48, rows: 48 },
      { cols: 64, rows: 64 },
      { cols: 80, rows: 80 },
      { cols: 96, rows: 96 },
      { cols: 128, rows: 128 },
      { cols: 160, rows: 160 },
      { cols: 192, rows: 192 }
    ],
    '2-3': [
      { cols: 64, rows: 96 },
      { cols: 96, rows: 144 },
      { cols: 128, rows: 192 }
    ],
    '3-2': [
      { cols: 96, rows: 64 },
      { cols: 144, rows: 96 },
      { cols: 192, rows: 128 }
    ],
    '3-4': [
      { cols: 48, rows: 64 },
      { cols: 96, rows: 128 }
    ],
    '4-3': [
      { cols: 64, rows: 48 },
      { cols: 128, rows: 96 }
    ],
    '4-5': [
      { cols: 64, rows: 80 },
      { cols: 128, rows: 160 }
    ],
    '5-4': [
      { cols: 80, rows: 64 },
      { cols: 160, rows: 128 }
    ]
  },
  orderTypes: ['', '申购', '优先购', '正式购', '铸造', '空投'],
  gridSizeOpts: [4, 8, 12, 16, 32],
  newfileOpts: {
    name: '未命名',
    ratio: '9-16'
  },
  feedback: {
    typeOpts: [
      { id: '1', label: '意见建议' },
      { id: '2', label: 'BUG反馈' },
      { id: '3', label: '商务合作' },
      { id: '4', label: '其他' }
    ],
    platform: '1',
    ver: '0.1.1',
    type: '',
    title: '',
    content: '',
    name: '',
    contact: ''
  },
  // Ai绘画晶钻消费
  paintConsume: {
    1: 40,
    2: 40,
    8: 40,
    91: 80,
    96: 70,
    100: 60,
    300: 50,
    999: 40
  },
  tools: {
    'k_86': ['V', 'default', 'my-cursor', '指针(V)'],
    'k_65': ['A', 'select', 'my-select-cursor', '部分选定(A)'],
    'k_67': ['C', 'freeSelect', 'my-select', '选取框(C)'],
    'k_81': ['Q', 'resize', 'my-resize', '缩放(Q)'],
    'k_80': ['P', 'fill', 'my-pen', '铅笔(P)'],
    'k_70': ['F', 'batchFill', 'my-fill', '油漆桶(F)'],
    'k_69': ['E', 'erase', 'my-eraser', '橡皮擦(E)'],
    'k_77': ['M', 'batchErase', 'my-eraser-batch', '魔术橡皮擦(M)'],
    'k_66': ['B', 'brush', 'my-brush', '笔刷(B)'],
    'k_83': ['S', 'spray', 'my-spray', '喷涂(S)'],
    'k_84': ['T', 'txt', 'my-txt', '文本(T)'],
    'k_76': ['L', 'line', 'my-line', '直线(L)'],
    'k_49': ['1', 'ellipse', 'my-blank-circle', '圆(1)'],
    'k_50': ['2', 'rectangle', 'my-blank-rectangle', '长方形(2)'],
    'k_51': ['3', 'triangle', 'my-blank-triangle', '三角形(3)'],
    'k_52': ['4', 'star', 'my-blank-star', '星形(4)'],
    'k_68': ['D', 'pick', 'my-pick', '滴管(D)'],
    'k_72': ['H', 'hand', 'my-hand', '手形工具(H)']
  },
  palette: {
    paletteId: '',
    opts: paletteOpts,
    data: paletteData
  },
  brickfy: paletteConf.brickfy,
  brickfyGrayDt: paletteConf.brickfyGray,
  colorfyDt: paletteConf.colorfy,
  bgDt: skusConf.bgs,
  frameDt: skusConf.frames,
  brickSkus: skusConf.brickSkus,
  brickMap: paletteConf.brickMap,
  whiteBrickNum: paletteConf.whiteBrickNum,
  brickDt: brickDt,
  darkenedBrickDt: darkenedBrickDt,
  shapes: {
    'line': '直线',
    'ellipse': '圆',
    'rectangle': '矩形',
    'triangle': '三角形',
    'star': '星形',
    'heart': '心形'
  },
  exportOpts: {
    formate: [
      ['GRIDY', 'gridy'],
      ['PNG', 'png'],
      ['GIF', 'gif'],
      ['JPG', 'jpg'],
      ['WEBP', 'webp'],
      ['ICO', 'ico'],
      ['PDF', 'pdf'],
      ['EXCEL', 'xls'],
      ['虚拟拼图(LXF)', 'lxf'],
      // ['LDD LXF（精简）', 'simpleLxf'],
      ['拼图图纸', 'paper'],
      // ['拼图图纸（精简）', 'simplePaper'],
      ['拼图清单', 'bom']
      // ['拼图清单（精简）', 'simpleBom'],
      // ['Sprite', 'sprite'],
      // ['Css', 'css'],
      // ['Svg', 'svg']
    ],
    gridSize: [
      ['1px', 1],
      ['2px', 2],
      ['4px', 4],
      ['6px', 6],
      ['8px', 8],
      ['12px', 12],
      ['16px', 16],
      ['32px', 32],
      ['64px', 64]
    ],
    fillShape: [
      ['默认', 'default'],
      ['方形', 'square'],
      ['圆形', 'circle'],
      ['无', '']
    ]
  },
  tradeState: {
    // 获取交易状态
    '-6': '禁用',
    '-5': '审核中',
    '-4': '审核拒绝',
    '-3': '审核忽略',
    '-2': '不存在',
    '-1': '实名认证不通过',
    '0': '未实名',
    '1': '实名认证中',
    '2': '已实名认证',
    '3': '未设置交易密码',
    '4': '账号支持交易'
  },
  view: viewConf.view,
  state: stateConf.state,
  schema: schemaConf.schema
}
