/**
 * @author funfly
 * @mail 389193@qq.com
 * @date 2022-1-29
 * @copyright Gridy.Art
 */
import utils from '@/js/utils'
import axios from 'axios'
import md5 from 'js-md5'
import { Db } from '@/js/db'
import conf from '@/js/conf/conf'
import COSSDK from '@/js/sdk/cosSDK'
const service = {
  mainHost: conf.hosts().mainHost,
  zoneApiHost: conf.hosts().zoneApiHost,
  apiHost: conf.hosts().apiHost,
  storeHost: conf.hosts().storeHost,
  db: new Db(),
  errMsg: {
    'BAD_REQUEST': '请求异常，请联系客服',
    'UNAUTHORIZED': '请登录',
    'REQUEST_DENIED': '本次请求被拒绝',
    'NOT_FOUND': '未找到数据',
    'METHOD_NOT_ALLOWED': '不支持的请求方式',
    'NOT_ACCEPTABLE': '不可接受的请求',
    'UNSUPPORTED_MEDIA_TYPE': '不可接受的请求',
    'REQUEST_RATE_LIMITED': '请勿频繁刷新，请稍后重试',
    'INTERNAL_SERVER_ERROR': '请求出错，请联系客服',
    'BAD_GATEWAY': '网关出错，请联系客服',
    'SERVICE_UNAVAILABLE': '请求不可用，请联系客服'
  },
  getCosUrl: function(url, type, ver) {
    if (!url) return ''
    url = url.toString()
    const bucket = conf.cosBucket
    type = type || 'avatar'
    ver = ver || '1'
    if (type === 'avatar' || type === 'background' || type === 'album_background') {
      url = url.replace('/' + type.replace('album_', '') + '?', '?')
      ver = '/' + type.replace('album_', '') + '?' + ver
    } else {
      url = url.replace('/webp?', '?')
      ver = '/webp?' + ver
    }
    if (url.split('?').length > 1) url = url.split('?')[0]
    return url ? url.replace('cos://', (bucket.cdnHost ? bucket.cdnHost : ('https://' + bucket.bucket + '.cos.' + bucket.region + '.myqcloud.com/')) + 'public/' + type + '/') + ver : ''
  },
  // 获取系列号
  getSN: function(snType = 0) {
    let dt = window.localStorage.getItem('sn_' + snType)
    dt = dt ? JSON.parse(dt) : {}
    return dt
  },
  // 保存系列号
  setSN: function(snType = 0, dt = {}, pass = false) {
    dt.pass = pass
    return window.localStorage.setItem('sn_' + snType, JSON.stringify(dt))
  },
  // 移除系列号
  removeSN: function() {
    for (let i = 0; i <= 2; i++) {
      window.localStorage.removeItem('sn_' + i)
    }
  },
  // 获取当前登录用户信息
  getToken: function() {
    let token = window.localStorage.getItem('token')
    token = token ? JSON.parse(token) : {}
    const storeToken = window.localStorage.getItem('storeToken')
    token.storeToken = storeToken ? JSON.parse(storeToken) : {}
    token.uid = token.uid || token.userId || 0
    token.userId = token.uid
    token.avatar = token.avatar || token.avatarUrl
    token.expireTime = token.expireTime || (token.expiresIn + token.loginTime)
    token.expireDays = Math.floor((token.expireTime - new Date().getTime() / 1000) / 86400)
    token.session = (token.expireDays && token.accessToken) ? md5(token.accessToken) : ''
    return token
  },
  // 获取请求id
  getReqId(withSession = true, symbol = '?', token) {
    token = token || this.getToken()
    const platform = utils.platform()
    let reqid = symbol
    if (withSession && token.session) reqid = reqid + 'session=' + token.session + '&'
    return reqid + 'reqid=GA:' + utils.time('time') + ':' + platform.value + ':' + (token.uid || 0) + ':' + utils.uuid()
  },
  // 鉴权
  authentication: function() {
    const token = this.getToken()
    const curTime = new Date().getTime() / 1000
    if (!token.userId || !token.accessToken || token.expireTime < curTime) {
      return false
    }
    return true
  },
  // 发起网络请求
  req: function(opts, cb, mustLogin, rawResponse) {
    const token = this.getToken()
    opts.headers = opts.headers || {}
    if (this.authentication()) {
      opts.headers.Authorization = 'Bearer ' + token.accessToken
      opts.withCredentials = true
    } else if (mustLogin) {
      cb && cb('', 'login')
      return
    }
    if (opts.noCookie) delete opts.withCredentials
    if (opts.url) {
      const reqId = this.getReqId(false, '', token)
      if (opts.url.split('?').length > 1) {
        opts.url = opts.url + '&' + reqId
      } else {
        opts.url = opts.url + '?' + reqId
      }
    }
    // console.log('req', opts)
    axios(opts)
      .then((res) => {
        if (rawResponse) {
          cb && cb(res)
        } else {
          if (res.status === 200 && res.data) {
            if ((res.data.Code === 0 || res.data.Code === -8000 || res.data.Code === -8001) && res.data.Data) {
              // zoneApiHost
              cb && cb(res.data.Data, 'success', res.data.Message)
            } else if (parseInt(res.data.code) === 200) {
              // apihost
              cb && cb(res.data, 'success')
            } else if (parseInt(res.data.status) === 200) {
              // shophost
              cb && cb(res.data, 'success')
            } else if (res.data.Code === -4003 || res.data.code === 429) {
              cb && cb(res.data.msg || '请勿频繁操作', 'limit', res.data.waiting || 0)
            } else if (res.data.msg === 'UNAUTHORIZED') {
              cb && cb('请登录', 'login')
            } else {
              if (res.data.msg) {
                res.data.msg = this.errMsg[res.data.msg] || res.data.msg
              }
              cb && cb(res.data.Message || res.data.msg || res.data.message || '请求错误', 'error')
            }
          } else {
            cb && cb('内部错误', 'error')
          }
        }
      })
      // eslint-disable-next-line
      .catch((err) => {
        console.log('axios', opts, err)
        cb && cb('网络错误', 'error')
      })
  },
  // 统计用户行为
  setCount: function(userid, key, nums) {
    this.db.save('count', { userid: userid, key: key, count: nums, updateTime: utils.time('time') }, { userid: userid, key: key })
  },
  // 增加用户行为统计
  incCount: async function(userid, key) {
    const ret = await this.db.get('count', { userid: userid, key: key })
    let nums = 1
    if (ret) nums = ret.count + 1
    this.setCount(userid, key, nums)
  },
  // 减少用户行为统计
  decCount: async function(userid, key) {
    const ret = await this.db.get('count', { userid: userid, key: key })
    let nums = 0
    if (ret) nums = ret.count - 1
    nums = Math.max(0, nums)
    this.setCount(userid, key, nums)
  },
  // 获取用户行为统计
  getCount: async function(userid, key) {
    return await this.db.get('count', { userid: userid, key: key })
  },
  upload(file, type = 'user', userid = 0, cb) {
    const key = 'public/' + type + '/' + utils.caclUserPath(userid) + '/' + file.name
    this.COSSDK = new COSSDK()
    this.COSSDK.putObject({
      Bucket: conf.cosBucket.bucket,
      Region: conf.cosBucket.region,
      Key: key,
      Body: file
    }, (err, data) => {
      if (!err && data && data.statusCode === 200 && data.Location) {
        cb && cb('cos://' + key.replace('public/' + type + '/', ''), 'success')
      } else {
        cb && cb('太火爆了，请稍后重试', 'error')
      }
    })
  },
  // 删除主题
  deleteThread: function(threadId, cb) {
    const dt = {
      'threadId': threadId
    }
    this.req({ method: 'POST', url: this.zoneApiHost + 'thread.delete', data: dt }, (data, type) => {
      if (type === 'success' || type === 'login') {
        cb && cb(data, type)
      } else {
        cb && cb('', 'error')
      }
    }, true)
  },
  // 创建主题
  createThread: function(data, cb) {
    if (!data.content) return cb && cb('请输入发布内容', 'error')
    const dt = {
      'title': data.title || '',
      'categoryId': data.categoryId || 2,
      'content': data.content || { text: '' },
      'position': data.position || {},
      'price': data.price || 0,
      'freeWords': data.freeWords || 0,
      'attachmentPrice': data.attachmentPrice || 0,
      'draft': data.draft || 0,
      'anonymous': data.anonymous || 0,
      'realm': data.realm || 0,
      'refid': data.refid || 0
    }
    this.req({ method: 'POST', url: this.zoneApiHost + 'thread.create', data: dt }, (data, type, msg) => {
      if (type === 'success' || type === 'login') {
        if (msg) {
          cb && cb(msg)
        } else {
          cb && cb(data, type)
        }
      } else if (type === 'limit') {
        cb && cb('发布太快，请稍后重试', 'error')
      } else {
        cb && cb('', 'error')
      }
    }, true)
  },
  // 评论主题/回复评论
  // data {"id":38,"userId":1,"threadId":31,"replyPostId":null,"replyUserId":null,"commentPostId":null,"commentUserId":null,"content":"古德！","replyCount":null,"likeCount":null,"createdAt":"2022-03-21 14:22:17","isFirst":false,"isComment":false,"isApproved":1,"rewards":0,"canApprove":true,"canDelete":true,"canHide":true,"canEdit":true,"user":{"id":1,"nickname":"dreawu","groups":{"groupName":"管理员","isDisplay":false,"level":0},"avatar":"","likedCount":0,"isRealName":false},"images":[],"likeState":null,"canLike":true,"summaryText":"古德！","isDeleted":false,"redPacketAmount":0,"isLiked":false}
  createPosts: function(data, cb) {
    if (!data.content) return cb && cb('请输入评论内容', 'error')
    const dt = {
      'id': data.id || '',
      'content': data.content || '',
      'realm': data.realm || 0,
      'refid': data.refid || 0
    }
    if (data.replyid) {
      dt.replyId = data.replyid
      dt.isComment = !!data.iscomment
      if (data.commentPostId) {
        dt.commentPostId = data.commentPostId
      }
    }
    this.req({ method: 'POST', url: this.zoneApiHost + 'posts.create', data: dt }, (data, type, msg) => {
      if (type === 'success' || type === 'login') {
        if (msg) {
          cb && cb(msg)
        } else {
          cb && cb(data, type)
        }
      } else if (type === 'limit') {
        cb && cb('回复太快，请稍后重试', 'error')
      } else {
        cb && cb('', 'error')
      }
    }, true)
  },
  // 删除评论
  deletePosts: function(postId, cb) {
    const dt = { 'postId': postId, 'data': { 'attributes': { 'isDeleted': 1 }}}
    this.updatePosts(dt, cb)
  },
  // 赞主题/评论
  praise: function(id, postId, cb) {
    const dt = { 'id': id, 'postId': postId, 'data': { 'attributes': { 'isLiked': true }}}
    this.updatePosts(dt, cb)
  },
  // 取消赞/评论
  unpraise: function(id, postId, cb) {
    const dt = { 'id': id, 'postId': postId, 'data': { 'attributes': { 'isLiked': false }}}
    this.updatePosts(dt, cb)
  },
  // 收藏/取消收藏
  collect: function(id, isFavorite, cb) {
    this.threadsOperate(id, { 'isFavorite': isFavorite }, cb)
  },
  // 主题操作
  threadsOperate: function(id, data, cb) {
    const dt = { 'id': id }
    // 收藏、取消收藏
    if (typeof data.isFavorite !== 'undefined') dt.isFavorite = data.isFavorite
    // 置顶、取消置顶
    if (typeof data.isSticky !== 'undefined') dt.isSticky = data.isSticky
    // 推荐、取消推荐
    if (typeof data.isEssence !== 'undefined') dt.isEssence = data.isEssence
    this.req({ method: 'POST', url: this.zoneApiHost + 'threads/operate', data: dt }, cb, true)
  },
  // 删除主题
  threadDelete: function(id, cb) {
    const dt = { 'threadId': id }
    this.req({ method: 'POST', url: this.zoneApiHost + 'thread.delete', data: dt }, cb, true)
  },
  // 发送站内短信
  sendMessage: function(dialogId, messageText, isImage = false, cb, recipientUserId = 0) {
    const dt = {
      'isImage': isImage,
      'messageText': messageText || ''
    }
    if (dialogId) dt.dialogId = dialogId
    if (recipientUserId) dt.recipientUserId = recipientUserId
    this.req({ method: 'POST', url: this.zoneApiHost + 'dialog' + (recipientUserId ? '' : '/message') + '.create', data: dt }, cb, true)
  },
  // 举报
  reports: function(threadId, type, reason, userId, cb, postId, workId, albumId) {
    const dt = {
      'threadId': threadId,
      'type': type || 0,
      'reason': reason || '',
      'userId': userId || 0
    }
    if (postId) dt.postId = postId
    if (workId) dt.workId = workId
    if (albumId) dt.albumId = albumId
    this.req({ method: 'POST', url: this.zoneApiHost + 'reports', data: dt }, cb, true)
  },
  // 取消关注
  unfollow: function(userid, cb) {
    const dt = {
      'id': userid,
      'type': 1
    }
    this.req({ method: 'POST', url: this.zoneApiHost + 'follow.delete', data: dt }, cb, true)
  },
  // 关注
  follow: function(userid, cb) {
    const dt = {
      'toUserId': userid
    }
    this.req({ method: 'POST', url: this.zoneApiHost + 'follow.create', data: dt }, cb, true)
  },
  // 设置交易密码
  setPayPassword: function(mobile, code, payPassword, payPasswordConfirmation, cb) {
    const dt = {
      mobile: mobile,
      code: code,
      payPassword: payPassword,
      payPasswordConfirmation: payPasswordConfirmation
    }
    this.req({ method: 'POST', url: this.zoneApiHost + 'users/sms.reset.pay.pwd', data: dt }, cb, true)
  },
  // 设置密码
  setPassword: function(mobile, code, password, cb) {
    const dt = {
      mobile: mobile,
      code: code,
      password: password
    }
    this.req({ method: 'POST', url: this.zoneApiHost + 'users/sms.reset.pwd', data: dt }, cb, true)
  },
  // 短信认证
  smsVerify: function(mobile, code, cb) {
    const dt = {
      mobile: mobile,
      code: code
    }
    this.req({ method: 'POST', url: this.zoneApiHost + 'users/sms.verify', data: dt }, cb, true)
  },
  // 重新绑定手机号码
  smsRebind: function(mobile, code, cb) {
    const dt = {
      mobile: mobile,
      code: code
    }
    this.req({ method: 'POST', url: this.zoneApiHost + 'users/sms.rebind', data: dt }, cb, true)
  },
  // 创建订单
  createOrder: function(order, cb) {
    if (!order.amount) {
      cb && cb(false)
      return
    }
    const dt = {
      amount: order.amount,
      groupId: order.groupId || 0,
      title: order.title || '无',
      type: order.type || 0,
      isAnonymous: false
    }
    this.req({ method: 'POST', url: this.zoneApiHost + 'order.create', data: dt }, cb, true)
  },
  // 更新用户信息
  updateUser: function(data, cb) {
    const dt = {}
    if (data.username) dt.username = data.username
    if (data.nickname) dt.nickname = data.nickname
    if (data.signature) dt.signature = data.signature
    this.req({ method: 'POST', url: this.zoneApiHost + 'users/update.user', data: dt }, cb, true)
  },
  // 更新主题
  // data {'pid':35,'postId':35,'threadId':31,'content':'','likeCount':3,'likePayCount':3,'replyCount':0,'isFirst':true,'isApproved':1,'updatedAt':'2022-03-18 18:33:35','isLiked':true,'canLike':true,'canFavorite':true,'isFavorite':false,'rewards':0,'redPacketAmount':0}
  updatePosts: function(data, cb) {
    const dt = {
      'postId': data.postId || '0',
      'data': data.data || {}
    }
    if (data.id) {
      dt.id = data.id
    }
    this.req({ method: 'POST', url: this.zoneApiHost + 'posts.update', data: dt }, cb, true)
  },
  // 获取未读信息
  getUnread: function(cb) {
    this.req({ method: 'GET', url: this.apiHost + 'dialog/action/type/unread' }, cb, true)
  },
  // 获取未读信息
  getDqzUnread: function(cb) {
    this.req({ method: 'GET', url: this.zoneApiHost + 'unreadnotification?author=true' }, (data, type) => {
      if (type === 'success') {
        if (data.status === 0) {
          const token = this.getToken()
          // 更新token
          token.nickname = data.nickname
          token.avatar = data.avatar
          window.localStorage.setItem('token', JSON.stringify(token))
          window.localStorage.setItem('storeToken', '')
          cb && cb(data, type)
        } else if (data.status === 1) {
          cb && cb('当前账号已被禁用', 'error')
        } else if (data.status === 2) {
          cb && cb('当前账号暂未通过审核', 'error')
        } else if (data.status === 3) {
          cb && cb('当前账号未通过审核', 'error')
        } else if (data.status === -1) {
          cb && cb('', 'login')
        }
      } else {
        cb && cb(data, type)
      }
    }, true)
  },
  // 退出登录
  logout: function(cb) {
    this.actionPost('user', { type: 'logout' }, () => {
      window.localStorage.setItem('token', '')
      window.localStorage.setItem('storeToken', '')
      cb && cb()
    })
  },
  // 用户登录
  // userStatus 用户状态：0 => '正常',1 => '禁用',2 => '审核中',3 => '审核拒绝',4 => '审核忽略'
  login: function(username, password, cb) {
    if (username && password) {
      this.req({ method: 'POST', data: { username: username, password: password, type: 'mobilebrowser_username_login' }, url: this.zoneApiHost + 'users/username.login' }, (data, type) => {
        if (type === 'success') {
          this.loginSuccess(data, type, cb)
        } else {
          cb && cb(data, type)
        }
      })
    }
  },
  // 用户登录
  // userStatus 用户状态：0 => '正常',1 => '禁用',2 => '审核中',3 => '审核拒绝',4 => '审核忽略'
  smsLogin: function(mobile, code, inviteCode, cb) {
    if (mobile && code) {
      this.req({ method: 'POST', data: { mobile: mobile, code: code, inviteCode: inviteCode, type: 'mobilebrowser_sms_login' }, url: this.zoneApiHost + 'users/sms.login' }, (data, type) => {
        if (type === 'success') {
          this.loginSuccess(data, type, cb)
        } else {
          cb && cb(data, type)
        }
      })
    }
  },
  // 单点登录到商城
  loginStore: function(cb) {
    if (!this.authentication()) {
      return cb && cb('', 'login')
    }
    const token = this.getToken()
    if (token.storeToken && token.storeToken.userId && token.storeToken.token) {
      return cb && cb(token.storeToken, 'success')
    } else if (token.session) {
      this.req({ method: 'GET', data: {}, headers: { platform: 'GA', storeId: 10001 }, noCookie: true, url: this.storeHost + 'index.php?s=/api/passport/sso_login&session=' + token.session }, (data, type) => {
        if (type === 'success') {
          window.localStorage.setItem('storeToken', JSON.stringify(data.data))
          cb && cb(data.data, type)
        } else {
          cb && cb(data, type)
        }
      })
    } else {
      return cb && cb('', 'login')
    }
  },
  // 同步收货地址到商城
  syncAddress: function(address, cb, storeId = 10001) {
    this.loginStore((dt, type) => {
      if (type === 'success') {
        this.req({ method: 'POST', data: address, headers: { platform: 'GA', storeId: storeId, 'Access-Token': dt.token }, noCookie: true, url: this.storeHost + 'index.php?s=/api/address/sync' }, cb)
      } else {
        cb && cb(dt, type)
      }
    })
  },
  // 同步购物车数据到商城
  syncCart: function(goods, cb, storeId = 10001) {
    this.loginStore((dt, type) => {
      if (type === 'success') {
        this.req({ method: 'POST', data: { goods: goods }, headers: { platform: 'GA', storeId: storeId, 'Access-Token': dt.token }, noCookie: true, url: this.storeHost + 'index.php?s=/api/cart/sync' }, cb)
      } else {
        cb && cb(dt, type)
      }
    })
  },
  // 获取结算信息
  checkOut: function(cartIds, cb, couponId = 0, delivery = 0, isUsePoints = 0, storeId = 10001) {
    this.loginStore((dt, type) => {
      if (type === 'success') {
        const qs = '&mode=cart&delivery=' + delivery + '&couponId=' + couponId + '&isUsePoints=' + isUsePoints + '&cartIds=' + cartIds.join(',')
        this.req({ method: 'GET', data: {}, headers: { platform: 'GA', storeId: storeId, 'Access-Token': dt.token }, noCookie: true, url: this.storeHost + 'index.php?s=/api/checkout/order' + qs }, cb)
      } else {
        cb && cb(dt, type)
      }
    })
  },
  // 领取优惠券
  receiveCoupon: function(couponId, cb, storeId = 10001) {
    this.loginStore((dt, type) => {
      if (type === 'success') {
        this.req({ method: 'POST', data: { couponId: couponId }, headers: { platform: 'GA', storeId: storeId, 'Access-Token': dt.token }, noCookie: true, url: this.storeHost + 'index.php?s=/api/myCoupon/receive' }, cb)
      } else {
        cb && cb(dt, type)
      }
    })
  },
  // 提交下单 (默认使用GA收银台支付)
  orderNow: function(cartIds, remark = '', cb, couponId = 0, order_source = 0, order_source_id = 0, order_source_uid = 0, storeId = 10001) {
    this.loginStore((dt, type) => {
      if (type === 'success') {
        const delivery = 0
        const isUsePoints = 0
        const qs = '&mode=cart&order_source=' + order_source + '&order_source_id=' + order_source_id + '&order_source_uid=' + order_source_uid + '&delivery=' + delivery + '&couponId=' + couponId + '&isUsePoints=' + isUsePoints + '&cartIds=' + cartIds.join(',')
        this.req({ method: 'POST', data: { remark: remark, payType: 0 }, headers: { platform: 'GA', storeId: storeId, 'Access-Token': dt.token }, noCookie: true, url: this.storeHost + 'index.php?s=/api/checkout/submit' + qs }, cb)
      } else {
        cb && cb(dt, type)
      }
    })
  },
  // 确认收货
  receipt: function(orderId, cb, storeId = 10001) {
    this.loginStore((dt, type) => {
      if (type === 'success') {
        this.req({ method: 'POST', data: { orderId: orderId }, headers: { platform: 'GA', storeId: storeId, 'Access-Token': dt.token }, noCookie: true, url: this.storeHost + 'index.php?s=/api/order/receipt' }, cb)
      } else {
        cb && cb(dt, type)
      }
    })
  },
  // 单点登录（未启用）
  ssoLogin: function(session, ssoToken, cb, storeId = 10001) {
    if (session) {
      this.req({ method: 'GET', data: {}, url: this.apiHost + 'sso&session=' + session + '&sso_token=' + ssoToken }, (data, type) => {
        if (type === 'success') {
          data.token.userStatus = 0
          this.loginSuccess(data.token, type, cb)
        } else {
          cb && cb(data, type)
        }
      })
    }
  },
  // 获取单点登录token（未启用）
  getSsoToken: function(cb) {
    this.req({ method: 'GET', data: {}, url: this.apiHost + 'sso/action/type/token' }, cb, true)
  },
  loginSuccess: function(data, type, cb) {
    data.loginTime = data.loginTime || new Date().getTime() / 1000
    if (data.userStatus === 0) {
      // 保存token
      data.uid = data.uid || data.userId
      data.avatar = data.avatar || data.avatarUrl
      window.localStorage.setItem('token', JSON.stringify(data))
      window.localStorage.setItem('storeToken', '')
      // 需要激活：设置昵称
      if (data.isMissNickname || !data.nickname) return cb && cb('', 'active')
      cb && cb('登录成功', 'success', data)
    } else if (data.userStatus === 1) {
      cb && cb('当前账号已被禁用', 'error')
    } else if (data.userStatus === 2) {
      cb && cb('当前账号暂未通过审核', 'error')
    } else if (data.userStatus === 3 || data.userStatus === 4) {
      cb && cb('当前账号未通过审核', 'error')
    } else if (data.userStatus === 10) {
      // 保存token
      data.uid = data.uid || data.userId
      data.avatar = data.avatar || data.avatarUrl
      window.localStorage.setItem('token', JSON.stringify(data))
      window.localStorage.setItem('storeToken', '')
      // 需要激活：待填写注册扩展信息
      cb && cb('', 'active')
    } else {
      cb && cb('登录失败，请重试', 'error')
    }
  },
  // DZQ分页获取数据
  pageGet: function(source, perPage, page, filter, cb, mustLogin, cache) {
    if (!source) cb && cb('', '')
    let filterStr = ''
    if (utils.isObject(filter)) {
      for (const k in filter) {
        if (filter[k]) filterStr = '&filter[' + k + ']=' + filter[k]
      }
    }
    this.req({ method: 'GET', data: {}, url: this.zoneApiHost + source + '?perPage=' + perPage + '&page=' + page + filterStr }, cb, mustLogin, cache)
  },
  // 发送短信验证码
  sendSms: function(type, mobile, cb) {
    const data = {
      captchaRandStr: '',
      captchaTicket: '',
      mobile: mobile,
      type: type
    }
    this.req({ method: 'POST', data: data, url: this.zoneApiHost + 'users/sms.send' }, cb, false, false)
  },
  // 设置昵称
  setNickname: function(nickname, cb) {
    this.req({ method: 'POST', data: { nickname: nickname }, url: this.zoneApiHost + 'users/nickname.set' }, cb, true, false)
  },
  // 获取登录二维码
  qrcode: function(cb) {
    this.req({ method: 'GET', data: {}, url: this.zoneApiHost + 'users/pc/wechat/h5.genqrcode?type=pc_login&redirectUri=' + this.mainHost + 'user%2Fwx-authorization%3Ftype%3Dpc' }, (res) => {
      if (res.status === 200 && res.data && res.data.Code === 0 && res.data.Data) {
        cb && cb(res)
      }
    }, false, true)
  },
  // 获取二维码登录信息
  qrlogin: function(sessionToken, cb) {
    if (sessionToken) {
      this.req({ method: 'GET', data: {}, url: this.zoneApiHost + 'users/pc/wechat/h5.login?sessionToken=' + sessionToken + '' }, (res) => {
        if (res.status === 200 && res.data && (res.data.Code === 0 || res.data.Code === -8000 || res.data.Code === -8001) && res.data.Data) {
          this.loginSuccess(res.data.Data, 'success', cb)
        }
      }, false, true)
    }
  },
  // 提现
  withdraw: function(amount, account, bankcardid, cb) {
    if (amount && account) {
      const data = {
        'cashApplyAmount': amount,
        'receiveAccount': account,
        'bankcardid': bankcardid
      }
      this.req({ method: 'POST', data: data, url: this.zoneApiHost + 'wallet/cash' }, cb, true)
    }
  },
  // 读取缓存
  getCache: async function(url) {
    const cacheKey = md5(url)
    const cacheDt = await this.db.get('cache', { key: cacheKey })
    if (cacheDt && cacheDt.expireTime > utils.time('time')) {
      return cacheDt.value
    } else {
      this.db.delete('cache', { key: cacheKey })
      return
    }
  },
  // 写缓存
  addCache: function(url, res, type, ttl) {
    if (type === 'success') {
      const cacheKey = md5(url)
      this.db.save('cache', { key: cacheKey, value: res, expireTime: utils.time('time') + (ttl || 3600) }, { key: cacheKey })
    }
  },
  // 分页获取作品
  listGet: async function(table, params, cb, mustLogin, cache) {
    let url = this.apiHost + table + '/list'
    Object.keys(params).map((k) => {
      if (!params[k]) params[k] = 0
      url = url + '/' + k + '/' + params[k]
    })
    if (cache) {
      const cacheData = await this.getCache(url)
      if (cacheData) {
        return cb && cb(cacheData, 'success')
      }
    }
    this.req({ method: 'GET', data: {}, url: url }, (res, type, waiting) => {
      if (type !== 'success') console.error()
      cb && cb(res, type, waiting)
      if (cache) this.addCache(url, res, type)
    }, mustLogin)
  },
  actionGet: async function(table, params, cb, mustLogin, cache) {
    let url = this.apiHost + table + '/action'
    Object.keys(params).map((k) => {
      if (!params[k]) params[k] = 0
      url = url + '/' + k + '/' + params[k]
    })
    if (cache) {
      const cacheData = await this.getCache(url)
      if (cacheData) {
        return cb && cb(cacheData, 'success')
      }
    }
    this.req({ method: 'GET', data: {}, url: url }, (res, type, waiting) => {
      cb && cb(res, type, waiting)
      if (cache) this.addCache(url, res, type)
    }, mustLogin)
  },
  actionDelete: async function(table, params, cb, mustLogin) {
    let url = this.apiHost + table + '/action'
    Object.keys(params).map((k) => {
      if (!params[k]) params[k] = 0
      url = url + '/' + k + '/' + params[k]
    })
    this.req({ method: 'DELETE', data: {}, url: url }, cb, mustLogin)
  },
  actionPost: function(table, data, cb, mustLogin) {
    const url = this.apiHost + table + '/action'
    this.req({ method: 'POST', data: data, url: url }, cb, mustLogin)
  },
  actionPut: function(table, data, cb, mustLogin) {
    const url = this.apiHost + table + '/action'
    this.req({ method: 'PUT', data: data, url: url }, cb, mustLogin)
  },
  // 获取详情
  get: async function(table, id, cb, mustLogin, params, cache) {
    let url = this.apiHost + table + '/' + id
    if (params) {
      Object.keys(params).map((k) => {
        if (!params[k]) params[k] = 0
        url = url + '/' + k + '/' + params[k]
      })
    }
    if (cache) {
      const cacheData = await this.getCache(url)
      if (cacheData) {
        return cb && cb(cacheData, 'success')
      }
    }
    this.req({ method: 'GET', data: {}, url: url }, (res, type, waiting) => {
      cb && cb(res, type, waiting)
      if (cache) this.addCache(url, res, type)
    }, mustLogin)
  },
  // 添加
  post: function(table, data, cb, mustLogin) {
    const url = this.apiHost + table
    this.req({ method: 'POST', data: data, url: url }, cb, mustLogin)
  },
  // 修改
  put: function(table, id, data, cb, mustLogin) {
    const url = this.apiHost + table + '/' + id
    this.req({ method: 'PUT', data: data, url: url }, cb, mustLogin)
  },
  // 删除
  delete: function(table, id, cb, mustLogin) {
    const url = this.apiHost + table + '/' + id
    this.req({ method: 'DELETE', data: {}, url: url }, cb, mustLogin)
  },
  // 批量获取详情
  batchGet: async function(table, ids, cb, mustLogin, params, cache) {
    let url = this.apiHost + table + '/batch/ids/' + ids
    if (params) {
      Object.keys(params).map((k) => {
        if (!params[k]) params[k] = 0
        url = url + '/' + k + '/' + params[k]
      })
    }
    if (cache) {
      const cacheData = await this.getCache(url)
      if (cacheData) {
        return cb && cb(cacheData, 'success')
      }
    }
    this.req({ method: 'GET', data: {}, url: url }, (res, type, waiting) => {
      cb && cb(res, type, waiting)
      if (cache) this.addCache(url, res, type)
    }, mustLogin)
  },
  // 批量添加
  batchPost: function(table, data, cb, mustLogin) {
    const url = this.apiHost + table + '/batch'
    this.req({ method: 'POST', data: data, url: url }, cb, mustLogin)
  },
  // 批量修改
  batchPut: function(table, ids, data, cb, mustLogin) {
    const url = this.apiHost + table + '/batch/ids/' + ids
    this.req({ method: 'PUT', data: data, url: url }, cb, mustLogin)
  },
  // 批量删除
  batchDelete: function(table, ids, cb, mustLogin) {
    const url = this.apiHost + table + '/batch/ids/' + ids
    this.req({ method: 'DELETE', data: {}, url: url }, cb, mustLogin)
  }
}
export default service
